//---------------------------------------------
//
//  Variables
//
//    1. Bootstrap Fonts
//    2. Bootstrap Globals
//    3. Bootstrap Colors
//    4. Bootstrap Container Width
//
//---------------------------------------------

//---------------------------------------------
// 1. Bootstrap Fonts
//---------------------------------------------

// $font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-base: "Lato", sans-serif;

//---------------------------------------------
// 2. Bootstrap Globals
//---------------------------------------------

$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;

//---------------------------------------------
// 3. Bootstrap Colors
//---------------------------------------------

$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;

// $primary:    #0d6efd;
$primary:    rgb(255, 48, 48);
// $secondary:  #6c757d;
$secondary:  rgba(59, 58, 58, 0.7);
$success:    #198754;
$info:       #0dcaf0;
$warning:    #ffc107;
$danger:     #dc3545;
$light:      #f8f9fa;
$dark:       #212529;

// body
$body-color: $white; // The default text color
$body-bg: #141617;
$body-emphasis-color: $white;

// links
$link-color: $white;

// Navbar
$navbar-nav-link-padding-x: 2rem;

$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;
$border-radius-xl: 0;
$border-radius-xxl: 0;
$border-radius-pill: 0;

$btn-border-radius: 100px;
$btn-border-radius-sm: 100px;
$btn-border-radius-lg: 100px;

$input-border-radius: 0;
$input-border-radius-sm: 0;
$input-border-radius-lg: 0;

$input-border-width: 0;
$input-bg: transparent;

$input-box-shadow: none;
$input-focus-box-shadow: none;

//---------------------------------------------
// 4. Bootstrap Container Width
//---------------------------------------------

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);
