/* MEDIA BREAKPOINTS
-------------------------------------------------- */

// ### DOWN
//
// From large to small devices

// No media query necessary for xs breakpoint as it's effectively `@media (max-width: 0) { ... }`
@include media-breakpoint-down(sm) {}

@include media-breakpoint-down(md) {}

@include media-breakpoint-down(lg) {
  .navbar-nav {
    // height: calc(100vh - 96px);
    height: calc(100vh - 118px);
    margin-top: 36px;
  }

  .form-wrap {
    // margin-top: 250px;
  }

  #jumbotron {
    .content {
      .hero-img {
        img {
          position: relative;
          // bottom: 250px;
          // z-index: -1;
        }
      }
    }
  }

  #client-logo {
    .slick-dots {
      bottom: -40px;
    }
  }
}

@include media-breakpoint-down(xl) {}

@include media-breakpoint-down(xxl) {}

// ### UP
//
// From small to large devices

// No media query necessary for xs breakpoint as it's effectively `@media (min-width: 0) { ... }`
@include media-breakpoint-up(sm) {}

@include media-breakpoint-up(md) {}

@include media-breakpoint-up(lg) {}

@include media-breakpoint-up(xl) {}

@include media-breakpoint-up(xxl) {}

// ### ONLY
//
// Only breakpoints

@include media-breakpoint-only(xs) {}

@include media-breakpoint-only(sm) {}

@include media-breakpoint-only(md) {}

@include media-breakpoint-only(lg) {}

@include media-breakpoint-only(xl) {}

@include media-breakpoint-only(xxl) {}
