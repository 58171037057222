//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "fonts";

// select2
@import "select2-bs5";

// Font Awesome
@import "fa-variables";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/solid";

// If you do not want to load the customized bootstrap version, just use the following line.
// @import "~bootstrap/scss/bootstrap";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

body {
  min-height: 75rem;
  padding-top: 4.5rem;
}

.navbar-toggler {
  border: none !important;
  border-radius: none !important;

  &:focus {
    box-shadow: none !important;
  }
}

.navbar-toggler span {
  display: block;
  background-color: $white;
  height: 3px;
  width: 25px;
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;
  left: 0;
  opacity: 1;
  transition: all 0.35s ease-out;
  transform-origin: center left;
}

.navbar-toggler span:nth-child(1) {
  transform: translate(0%, 0%) rotate(0deg);
}

.navbar-toggler span:nth-child(2) {
  opacity: 1;
}

.navbar-toggler span:nth-child(3) {
  transform: translate(0%, 0%) rotate(0deg);
}

.navbar-toggler span:nth-child(1) {
  margin-top: 0.3em;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  transform: translate(15%, -33%) rotate(45deg);
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  opacity: 0;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  transform: translate(15%, 33%) rotate(-45deg);
}

.text-red {
  color: #FF3030;
}

.btn-white {
  color: $white;

  &:hover,
  &:active {
    color: $white !important;
  }

  &:hover {
    background: $secondary;
    border-color: $secondary;
  }
}

.btn-wide {
  padding-left: 2rem;
  padding-right: 2rem;
}

.btn-x-wide {
  padding-left: 4rem;
  padding-right: 4rem;
}

.btn-tall {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.btn-block {
  display: block !important;
  text-align: center !important;
  width: 100%;
  color: $white;

  &:hover {
    background: $secondary;
    border-color: $secondary;
    color: $white;
  }
}

.nav-main {
  box-shadow: 0 1px 5px #000000;
  background-color: #141617;
}

.vid-bg {
  video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    opacity: 0.3;
    transform: translateX(-50%) translateY(-50%);
  }

  .content {
    position: relative;
    z-index: 9;
  }
}

#jumbotron {
  position: relative;
  overflow: hidden;
  // background: transparent url('../images/kibarin-background.png') no-repeat bottom right;
  background-size: 80%;

  .content {
    .hero-img {
      img {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }

  .form-wrap {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 18px;

    .form-label,
    .form-control {
      color: rgb(59, 58, 58);
    }

    .form-control {
      border-bottom: 1px solid rgba(59, 58, 58, 0.23);
    }
  }

  // .overlay {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   height: 100%;
  //   width: 100%;
  //   background-color: black;
  //   opacity: 0.9;
  //   z-index: 1;
  // }
}

#client-logo {
  background-color: $white;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  position: relative;

  .svg-divider {
    overflow: hidden;
    // &:before {
    //   // content:"";
    //   position: absolute;
    //   bottom: -0.1vw;
    //   left: -0.1vw;
    //   right: -0.1vw;
    //   top: -0.1vw;
    //   background-size: 110% 26px;
    //   background-position: 50% 100%;
    //   background-repeat: no-repeat;
    //   background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 2" preserveAspectRatio="none"><path d="M0 2q5-4 10 0z" fill="%23000000"/></svg>');
    // }
  }

  svg {
    position: absolute;
    width: 100%;
    bottom: -1px;
    height: 20px;
    left: 50%;
    transform: translate(-50%, -0);
    fill: #141617;
  }
}

// @media (min-width:2100px){
// .svg_divider::before{
// background-size: 137% calc(2vw + 71px);
// }

#service {
  .h1 {
    &:after {
      content: "";
      display: block;
      border: none;
      border-bottom: .8rem solid $primary;
      opacity: 1;
      width: 15%;
      margin: 20px auto;
    }
  }
}

#contact {
  position: relative;
  overflow: hidden;
}

footer {
  .logo-footer {
    width: 224px;
  }

  p {
    font-size: 14px;
  }

  .social-icons {
    a {
      font-size: xx-large;
      color: darken($white, 40%);
      margin-left: 10px;
      margin-right: 10px;

      &:hover {
        color: $white;
      }
    }
  }

  .footer-links {
    h6 {
      margin-bottom: 1rem;
      font-weight: 700;
    }

    li {
      font-size: .9rem;
      margin-bottom: .8rem;

      a {
        text-decoration: none;
      }
    }
  }
}

//---------------------------------------------
// 3.Components
//---------------------------------------------

// @import "components/slider";
@import "components/slick";

//---------------------------------------------
// 4.Responsive
//---------------------------------------------

@import "responsive";
